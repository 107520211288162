import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ContactPage = props => {
  return (
    <Fragment>
      <Layout>
        <Img
          fadeIn={true}
          className="main-image-wrapper"
          fluid={props.data.contactImage.childImageSharp.fluid}
        />
        <h1>Contact</h1>
        <h2>get in touch</h2>
        <p>
          If you are interested in contacting me for business inquiries or a job
          offer, feel free to contact me via email <span><OutboundLink href="mailto:lauirvin98@gmail.com">lauirvin98@gmail.com</OutboundLink></span> or
          through my social media accounts and I will get back to you as soon as
          possible.
        </p>
        <div>
          <a href="mailto:lauirvin98@gmail.com">
            <button>Reach me</button>
          </a>
        </div>
      </Layout>
    </Fragment>
  )
}

export default ContactPage

export const contactImage = graphql`
  fragment contactImage on File {
    childImageSharp {
      fluid(maxWidth: 900) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const data = graphql`
  query {
    contactImage: file(relativePath: { eq: "media/main/contact.jpg" }) {
      ...contactImage
    }
  }
`
